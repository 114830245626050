import {Children, useEffect, useState} from 'react'
import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
// eslint-disable-next-line
import {Swiper, SwiperSlide} from 'swiper/react'
import {EffectFade, Autoplay, Pagination, Controller} from 'swiper'
import styled from 'styled-components'
import Arrow from './Arrow'
// eslint-disable-next-line
import 'swiper/css/bundle'


const StyledSwiperContainer = styled.div`
  position: relative;
`

const ArrowContainer = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
  width: 100%;
`

const StyledSwiper = styled(Swiper)`
  position: relative;

  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    border: 2px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.background2};
  }

  .swiper-pagination-bullet-active {
    background-color: ${({theme}) => theme.colors.primary};
  }
`

const Slider = ({
  slidesPerView, autoplay, pagination, children, prevArrowTitle,
  nextArrowTitle, hideArrows, control, ...props
}) => {
  const [slideNavState, setSlideNavState] = useState({})

  useEffect(() => {
    if (control) {
      setSlideNavState({isEnd: control.isEnd, isBeginning: control.isBeginning})
    }
  }, [control])


  const handleSlideChange = (swiper) => {
    if (swiper.isEnd !== slideNavState.isEnd || swiper.isBeginning !== slideNavState.isBeginning) {
      setSlideNavState({isEnd: swiper.isEnd, isBeginning: swiper.isBeginning})
    }
  }

  return (
    <StyledSwiperContainer>
      <StyledSwiper
          fadeEffect={{crossFade: true}}
          slidesPerView={slidesPerView}
          spaceBetween={slidesPerView === 1.1 ? 0 : 30}
          autoplay={autoplay ? {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          } : false}
          pagination={pagination && {
            el: '.swiper-pagination',
            type: 'bullets',
          }}
          controller={{control}}
          modules={[EffectFade, Autoplay, Pagination, Controller]}
          onSlideChange={handleSlideChange}
          onReachEnd={() => setSlideNavState((prevState) => ({...prevState, isEnd: true}))}
          onReachBeginning={() => setSlideNavState((prevState) => ({...prevState, isBeginning: true}))}
          {...props}
      >
        {Children.map(children, (child) => (
          <SwiperSlide className="swiper-slide">{child}</SwiperSlide>
        ))}
        <If condition={pagination}>
          <div className="swiper-pagination" />
        </If>
      </StyledSwiper>
      <If condition={children.length > 1 && !hideArrows}>
        <ArrowContainer>
          <Arrow
              inverted
              title={prevArrowTitle}
              onClick={() => control.slidePrev()}
              disabled={slideNavState.isBeginning}
          />
          <Arrow
              title={nextArrowTitle}
              onClick={() => control.slideNext()}
              disabled={slideNavState.isEnd}
          />
        </ArrowContainer>
      </If>
    </StyledSwiperContainer>
  )
}

Slider.defaultProps = {
  slidesPerView: 1,
  autoplay: false,
  pagination: false,
}

Slider.propTypes = {
  prevArrowTitle: PropTypes.string.isRequired,
  nextArrowTitle: PropTypes.string.isRequired,
  slidesPerView: PropTypes.number,
  autoplay: PropTypes.bool,
  pagination: PropTypes.bool,
  hideArrows: PropTypes.bool,
  control: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Slider
