import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {ChevronIcon} from '../../icons'
import media from '../../utils/media'
import omitProps from '../../utils/omitProps'


const StyledArrowContainer = styled('div').withConfig(omitProps(['inverted']))`
  display: grid;
  place-items: center;
  transform: translate(150%);
  transform-origin: ${({$inverted}) => $inverted ? 'right' : 'left'};
  margin-top: 0;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.primary};
  padding: 1rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  ${({inverted}) => inverted && css`
    transform: translate(-150%);
  `}
  ${({disabled}) => disabled && css`
    opacity: 1;
    background-color: ${({theme}) => theme.colors.aux};
  `}
  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledArrow = styled(ChevronIcon).withConfig(omitProps(['inverted']))`
  transform: rotate(270deg);
  height: 1rem;
  ${({inverted}) => inverted && css`
    transform: rotate(90deg);
  `}
`

const Arrow = ({title, inverted, disabled, ...props}) => {
  return (
    <StyledArrowContainer
        inverted={inverted}
        disabled={disabled}
        {...props}
    >
      <span className="sr-only">{title}</span>
      <StyledArrow color="text2" inverted={inverted} />
    </StyledArrowContainer>
  )
}

Arrow.defaultProps = {
}

Arrow.propTypes = {
  title: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Arrow
